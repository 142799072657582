/* eslint-disable */
import { combineReducers, createStore, applyMiddleware } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'
// import { connectRouter } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import {
  sidebarReducer,
  themeReducer,
  loginReducer,
  authReducer,
  ordersReducer,
  companiesReducer,
  usersReducer,
  storesReducer,
  cartReducer,
  inventoryItemsReducer,
  posCategoriesReducer,
  notificationReducer,
  permissionGroupReducer,
  phoneVerificationsReducer,
  districtsReducer,
  serialsReducer,
  paymentAccountReducers,
  sidebarItemReducer,
  roleReducer,
  taxReducer,
  regionReducer,
  hrWebClockinReducer,
  hrTimecardBreakReducer,
  hrSettingsStoreReducer,
  hrSettingsCompaniesReducer,
  user_stores,
  user_districts,
  user_regions,
  support_tickets,
  support_ticket_types,
  embedsAndLinksReducer,
  globalLoader,
  employeesReducer,
  requiredFieldsReducer,
  oneViewCompanyWidgetReducer,
  oneViewReducer,
  coordinatesReducer,
  hrUserAutoClockedDetailsReducer,
  publicContactUsReducer
} from '../../redux/reducers/index'
import rootSaga from '../../redux/sagas/index'
import createSagaInjector from '../../redux/injectSaga'

const sagaMiddleware = createSagaMiddleware()

const staticReducers = {
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  login: loginReducer,
  sidebar: sidebarReducer,
  posCategories: posCategoriesReducer,
  companies: companiesReducer,
  auth: authReducer,
  roles:roleReducer,
  permission_groups:permissionGroupReducer,
  orders: ordersReducer,
  users: usersReducer,
  tax:taxReducer,
  stores: storesReducer,
  cart: cartReducer,
  notification: notificationReducer,
  phoneVerification: phoneVerificationsReducer,
  districts: districtsReducer,
  serials: serialsReducer,
  payment_account: paymentAccountReducers,
  sidebar_items: sidebarItemReducer,
  regions: regionReducer,
  webclocks: hrWebClockinReducer,
  timecardbreaks: hrTimecardBreakReducer,
  hrsettingsstores: hrSettingsStoreReducer,
  hrsettingscompanies: hrSettingsCompaniesReducer,
  user_stores,
  user_regions,
  user_districts,
  support_tickets,
  support_ticket_types,
  embeds: embedsAndLinksReducer,
  global_loader: globalLoader,
  employees: employeesReducer,
  requiredFields: requiredFieldsReducer,
  oneViewCompanyWidget: oneViewCompanyWidgetReducer,
  oneView: oneViewReducer,
  coordinates: coordinatesReducer,
  hrUserAutoClockedDetails: hrUserAutoClockedDetailsReducer,
  publicContactUsReducer: publicContactUsReducer,
};
// const store = (initialState, context = {}) => ({
//   ...createStore(reducer, applyMiddleware(sagaMiddleware)),
//   runSaga: sagaMiddleware.run(rootSaga, context),
// });

function createReducer(asyncReducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

const store = (initialState, context = {}) => {
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(createReducer(), composeWithDevTools(
      applyMiddleware(sagaMiddleware)));
  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    if(key && !store.asyncReducers[key]) {
      store.asyncReducers[key] = asyncReducer;
      store.replaceReducer(createReducer(store.asyncReducers));
    }
  };
  store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga, context)
  store.runSaga = (p) => sagaMiddleware.run(p, context)
  // Return the modified store
  return store;
}
export default store