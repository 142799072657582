import React from 'react';

export const RmaRequestAppConfig = {
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'rmaRequestApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/ra-requests',
      component: React.lazy(() => import('./RaRequests')),
    },
    {
      path: '/ra-requests/new',
      component: React.lazy(() => import('./ManageRaRequests')),
    },
    {
      path: '/ra-requests/edit/:id(\\d+)',
      component: React.lazy(() => import('./ManageRaRequests')),
    },
    {
      path: '/ra-requests/:id(\\d+)',
      component: React.lazy(() => import('./SingleRaRequest')),
    },
    {
      path: '/traded-in-items',
      component: React.lazy(() => import('./TradedInItems')),
    },
    {
      path: '/inventory-leads/list',
      component: React.lazy(() => import('./InventoryLeads/List')),
    },
  ],
};

export default RmaRequestAppConfig;
